@import "fonts";

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: "Proxima Nova", Helvetica, Arial, sans-serif;
}

body {
  margin: 0;
  padding: 0;
}

// fix for instagram image download
body>img {
  width: 100%;
  height: auto;
}

// fullscreen chat
body.mobile-fullscreen-chat,
body.mobile-fullscreen-chat #__next {
  height: 0 !important;
  position: static !important;
  transform: none !important;
  overflow: hidden !important;
}